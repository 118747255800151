import React from "react";
import ReactModal from "react-modal"
import "./login.css"

const MyModalEn = ({ isOpen, onClose }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Modal"
      className="custom-modal"
      overlayClassName="custom-overlay"
    >
      <div id="w-oa4d384x" className="popup-center" style={{ zIndex: 1000001 }}>
        <div
          className="popup-wrapper p-absolute full-width full-height"
          style={{ height: 250 }}
        >
          <div className="popup-background p-absolute full-width full-height" />
          <div id="w-12w1mtn1" className="p-absolute cursor-pointer">
            <div className="rectangle-css full-mask-size mask-position full-height full-width" />
          </div>
          <div id="w-bwv7qptl" className="p-absolute">
            <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
              <div className="image-background p-absolute" />
            </div>
          </div>
          <div id="w-8qhj6edk" className="com-text-block p-absolute">
            <div className="text-block">
              <h4 className="text-block-css full-width">
                PLEASE SIGN IN TO CONTINUE
              </h4>
            </div>
          </div>
          <a
            href="/en/thanhtoanπ"
            id="w-47luuj44"
            className="com-button p-absolute cursor-pointer"
          >
            <div className="button-css full-height full-width">
              <span className="button-loader" />
              <div className="button-text full-width u-select-none">LOGIN</div>
            </div>
          </a>
        </div>
      </div>

    </ReactModal>
  );
};

export default MyModalEn;