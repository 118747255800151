import React, { useState, useEffect } from "react";
import "./SignIn.css";
import PiNetworkLogo from "./icons/pi-network.png";

import { getFirestore, doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";
import { isEqual } from "lodash";
import { generateJWT, verifyJWT } from "./jwtHelper";

const getUserName = async () => {
  const docRef = doc(db, "users", "_"); // Replace with your collection and document ID
  const docSnap = await getDoc(docRef);


  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return {};
  }
};

const SignIn = () => {
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("")
    var data = new FormData(e.target);
    let formObject = Object.fromEntries(data.entries());

    const userInfo = await getUserName(formObject);

    if (isEqual(userInfo, formObject)) {
      const token = generateJWT(userInfo);
      localStorage.setItem("_token", token);

      window.location.href = "/dashboard";
    } else {
      setErrorMessage("Invalid username or password")
    }

  };

  useEffect(() => {
    const result = verifyJWT(localStorage.getItem("_token"));
    if (result) {
      window.location.href = "/dashboard";
    }

  }, []);

  return (
    <div className="signin-container">
      <div className="signin-left">
        <h1
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            src={PiNetworkLogo}
            alt="Pi Network Logo"
            style={{ width: 32, height: "auto", marginRight: 8 }}
          />
          Login
        </h1>
        <p>Pi Wallet Management System</p>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="username">
              <i className="bi bi-person"></i> Username
            </label>
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              placeholder="Username"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">
              <i className="bi bi-lock"></i> Password
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              placeholder="Password"
              required
            />
          </div>
          {
            !!errorMessage && (<span>{errorMessage}</span>)
          }
          <button type="submit" className="btn btn-primary">
            Login
          </button>

        </form>
      </div>
      {/* <div className="signin-right">
        <h1>Sign up</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <button className="btn btn-secondary">Register Now!</button>
      </div> */}
    </div>
  );
};

export default SignIn;
