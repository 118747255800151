import React from "react";

export default function CartIcon() {
  return (
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAzUlEQVR4nN3SMQ4BQRjF8ZFI1CJqlQNIHMIScRpncArZROIGSgm9Tqnc1VBpBAn7l0k+obBrZmc2wqu/935TjFJ/ESDhmbAI4PYCnIG6d0QHmOGehUoL0PUArLOAErBxBPqpgCBDh/Fl5rgAVeCYYzwB2h8BQcIcwMRoXICW5fgJaBgDgqwsgJHteA3YG758ClRsgbEMzPXXtSob/qIrcAGaXscFKAMHckRZIANgVxhQeIAesAViIHC9e1fUhUci17uvAIGUI6DjevfbuQNmu0d3sL28ewAAAABJRU5ErkJggg=="
      style={{ width: 14, height: 14, marginRight: 8 }}
    />
  );
}
