import { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";

import Login from "./admin/SignIn";
import Dashboard from "./admin/Dashboard";
import Dashboard3TaiXiu from "./admin/Dashboard3-taixiu";

import MuaHangPiEn from "./mua-hang-pi";
import MuaHangPiVi from "./mua-hang-pi-in";
import ThanhToanPiVi from "./thanhtoan-vi";
import ThanhToanPiEn from "./thanhtoan";

import ThanhToanPiGlobal from "./thanhtoan-global";
import MuaHangPiGlobal from "./mua-hang-pi-global";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { initMetaPixel, trackPageView } from "./utils/metaPixel";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  useEffect(() => {
    const pixelId = "952811763271292"; // Replace with your Meta Pixel ID
    initMetaPixel(pixelId);
    trackPageView(); // Track page view when the app is loaded
  }, []);

  return (
    <>
      <Router>
        <main className="app">
          <Switch>
            <Route exact path="/admin" component={Login} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/taixiu" component={Dashboard3TaiXiu} />

            {/* vi */}
            <Route exact path="/" component={MuaHangPiVi} />
            <Route exact path="/muahangpi" component={MuaHangPiVi} />
            <Route exact path="/thanhtoanπ" component={ThanhToanPiVi} />

            {/* en */}
            <Route exact path="/en" component={MuaHangPiEn} />
            <Route exact path="/en/muahangpi" component={MuaHangPiEn} />
            <Route exact path="/en/thanhtoanπ" component={ThanhToanPiEn} />

            {/* global - vietnam version */}
            <Route exact path="/global" component={MuaHangPiGlobal} />
            <Route exact path="/global/muahangpi" component={MuaHangPiGlobal} />
            <Route
              exact
              path="/global/thanhtoanπ"
              component={ThanhToanPiGlobal}
            />
          </Switch>
        </main>
      </Router>
    </>
  );
}

export default App;
