import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDnqY80zals-0Hx7il1rHwb5udYniKHTo8",
  authDomain: "fe-support-tools.firebaseapp.com",
  projectId: "fe-support-tools",
  storageBucket: "fe-support-tools.firebasestorage.app",
  messagingSenderId: "127806752671",
  appId: "1:127806752671:web:5ec7deca3afcbdd6a79faa",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
