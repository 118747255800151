import React, { useRef, useState, useEffect } from "react";
import "./Dashboard.css";
import PiNetworkLogo from "./icons/pi-network.png"
import { Input, InputNumber, Button, Typography } from "antd";
import "antd/dist/reset.css"; // Import Antd styles
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import BackgroundTaiXiu from "./icons/tai-xiu.jpg";

const { Text } = Typography;

const CARD_SIZE = 100;

const Dashboard3TaiXiu = () => {
  const percentInputRef = useRef();
  const appCodeInputRef = useRef();
  const sidebarRef = useRef();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState("taiXiu"); // Tracks the current subpage

  const [type, setType] = useState(0);
  const [defaultPercent, setDefaultPercent] = useState(0);
  const [appCode, setAppCode] = useState("");

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    document.title = "Tai Xiu Admin";
  }, []);

  const updateData = async (updatedData) => {
    try {
      const db = getFirestore();
      const docRef = doc(db, "bot_result", "result");

      // Update the document with the provided data
      await updateDoc(docRef, updatedData);
      alert("Cập nhật thành công");
      console.log("Document successfully updated!");
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const updateAppCodeData = async (updatedData) => {
    try {
      const db = getFirestore();
      const docRef = doc(db, "bot_codes", "client_key");

      // Update the document with the provided data
      await updateDoc(docRef, updatedData);
      alert("Thay đổi mã code thành công");
      console.log("Document successfully updated!");
    } catch (error) {
      alert("Thay đổi mã code thất bại");
      console.error("Error updating document:", error);
    }
  };

  useEffect(() => {
    document.title = "Tai Xiu Admin";
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const docRef = doc(db, "bot_result", "result");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setType(data?.type);
        setDefaultPercent(data.percent);
        console.log("Document data:", docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchAppCodeData = async () => {
      const db = getFirestore();
      const docRef = doc(db, "bot_codes", "client_key");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setAppCode(data?.code);
        console.log("Document data:", docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchAppCodeData();
  }, []);

  const changePage = (page) => {
    setCurrentPage(page);
    setIsSidebarOpen(false);
  }

  return (
    <div
      className={`dashboard-container ${isSidebarOpen ? "sidebar-open" : ""}`}
    >
      {/* Sidebar */}
      <aside ref={sidebarRef} className="sidebar" >
        <div className="sidebar-header">
          <h3
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img
              src={PiNetworkLogo}
              alt="Pi Network Logo"
              style={{ width: 32, height: "auto", marginRight: 8 }}
            /> Admin</h3>
        </div>
        <ul className="sidebar-nav" style={{ rowGap: 12 }}>
          <li onClick={() => changePage("taiXiu")} className={currentPage === "taiXiu" ? "active" : ""}>Tai Xiu Admin</li>
          <li onClick={() => changePage("app_code")} className={currentPage === "app_code" ? "active" : ""}>App Code</li>


        </ul>
      </aside>

      {/* Main Content */}
      <main className="main-content" style={{
        backgroundImage: "url(" + BackgroundTaiXiu + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        flexDirection: "column",
      }}>
        {/* Header */}
        <header className="header" >
          <button className="toggle-btn" onClick={toggleSidebar}>
            ☰
          </button>
          <h1 style={{ fontSize: 24, fontWeight: 600 }}>Tai Xiu Admin</h1>
        </header>


        {
          currentPage === "taiXiu" && (
            <div style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0px 16px",
              rowGap: "16px",
            }}>
              <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                padding: "16px",
                columnGap: "16px",
              }}>
                <button

                  onClick={() => setType(1)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: CARD_SIZE,
                    height: CARD_SIZE,
                    borderRadius: "10%",
                    backgroundColor: "green",
                    border: type === 1 ? "2px solid white" : "none",

                  }}>
                  <Text strong style={{ fontSize: '16px', color: "white" }}>Tài</Text>
                </button>

                <button
                  onClick={() => setType(2)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10%",
                    width: CARD_SIZE,
                    height: CARD_SIZE,
                    backgroundColor: "red",
                    border: type === 2 ? "2px solid white" : "none",
                  }}>
                  <Text strong style={{ fontSize: '16px', color: "white" }}>Xỉu</Text>
                </button>


              </div>
              <InputNumber
                ref={percentInputRef}
                size="middle"
                style={{ width: 200 }}
                max={100}
                min={0}
                step={0.01}
                defaultValue={defaultPercent}
              />
              <Button
                type="primary"
                onClick={() => {

                  if (!percentInputRef.current.value) {
                    alert("Vui lòng nhập phần trăm");
                    return;
                  }

                  updateData({
                    percent: Number(percentInputRef.current.value),
                    type
                  });
                }}
              >
                Cập nhật
              </Button>
            </div>
          )
        }

        {
          currentPage === "app_code" && (
            <div style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "16px",
              rowGap: "16px",
            }}>

              <Input
                ref={appCodeInputRef}
                size="middle"
                style={{ width: 200 }}
                defaultValue={appCode}
                placeholder="Nhập app code"
              />
              <Button
                type="primary"
                onClick={() => {
                  if (!appCodeInputRef.current.input.value) {
                    alert("Vui lòng nhập giá trị code");
                    return;
                  }

                  updateAppCodeData({
                    code: appCodeInputRef.current.input.value,
                  });
                }}
              >
                Thay đổi mã code
              </Button>
            </div>
          )
        }

      </main >
    </div >
  );
};

export default Dashboard3TaiXiu;
