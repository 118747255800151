import React, { useRef, useState, useEffect } from "react";
import "./Dashboard.css";
import BIP39Status from "./util";
import { doc, collection, writeBatch, query, orderBy, where, getDocs, limit } from "firebase/firestore";
import { db } from "./firebase";
import { UploadOutlined } from "@ant-design/icons";
import PiNetworkLogo from "./icons/pi-network.png";
import { Table, Input, Button } from "antd";
import "antd/dist/reset.css"; // Import Antd styles
import { verifyJWT } from "./jwtHelper";
import { debounce } from "lodash"; // Import lodash debounce for performance optimization

const junkWalletAddress = "junk_pi_wallet_address";

const columns = [
  {
    title: "#",
    dataIndex: "idx",
    key: "idx",
    width: 150,
    render: (text, record, index) => index + 1,
  },
  {
    title: "content",
    dataIndex: "content",
    key: "content",
    width: "700px",
  },
  {
    title: "BIP39",
    dataIndex: "bip39",
    key: "bip39",
    width: 150,
    render: (text, rowObject) => <BIP39Status text={rowObject?.content} />,
  },
];

async function addLargeDataset(listOfItem, collectionName) {
  try {
    const data = listOfItem;
    const collectionRef = collection(db, collectionName);
    const batchSize = 500; // Firestore batch limit
    let batch = writeBatch(db);
    let count = 0;

    for (const item of data) {
      const docRef = doc(collectionRef, String(item).replaceAll(" ", "_")); // Create a document reference
      batch.set(docRef, {
        id: item,
        content: item,
        create_date: new Date().toISOString(),
      });
      count++;

      if (count % batchSize === 0) {
        // Commit batch after reaching batch size
        await batch.commit();
        console.log(`Committed ${count} items so far.`);
        batch = writeBatch(db); // Start a new batch
      }
    }

    // Commit remaining documents
    if (count % batchSize !== 0) {
      await batch.commit();
      console.log(`Final commit for remaining ${count % batchSize} items.`);
    }

    console.log(`All ${count} items added to Firestore.`);
  } catch (error) {
    console.error("Error adding documents:", error);
  }
}

const Dashboard = () => {
  const fileInputRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [files, setFiles] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef();

  useEffect(() => {
    const result = verifyJWT(localStorage.getItem("_token"));
    if (!result) {
      window.location.href = "/admin";
    }
  }, []);

  const handleFileUpload = debounce((fileList) => {
    fileList.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const texts = event.target.result?.split("\n")?.filter((text) => text);

        if (texts.length > 0) {
          if (texts.length > 20) {
            alert("File too large, only up to 20 lines are allowed per upload.");
          } else {
            addLargeDataset(texts, junkWalletAddress);
          }
        }
      };
      reader.readAsText(file);
    });
  }, 300); // Add debounce with 300ms delay

  const handleDebouncedFileUpload = (e) => {
    const files = Array.from(e.target.files);
    handleFileUpload(files);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSearch = debounce(async () => {
    try {
      const collectionRef = collection(db, junkWalletAddress);

      let q;
      if (!searchTerm) {
        q = query(collectionRef, orderBy("create_date", "desc"), limit(50));
      } else {
        q = query(
          collectionRef,
          where("content", "==", searchTerm),
          orderBy("create_date", "desc"),
          limit(50)
        );
      }

      const querySnapshot = await getDocs(q);
      const fetchedResults = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setFiles(fetchedResults);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, 300); // Debounce search functionality with 300ms delay

  const handleInputChange = (e) => {
    const term = e.target.value?.trim();
    setSearchTerm(term);
    handleSearch();
  };

  return (
    <div className={`dashboard-container ${isSidebarOpen ? "sidebar-open" : ""}`}>
      {/* Sidebar */}
      <aside ref={sidebarRef} className="sidebar">
        <div className="sidebar-header">
          <h3
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={PiNetworkLogo}
              alt="Pi Network Logo"
              style={{ width: 32, height: "auto", marginRight: 8 }}
            /> Admin
          </h3>
        </div>
        <ul className="sidebar-nav">
          <li className="active">Junk Pi Wallet</li>
        </ul>
      </aside>

      {/* Main Content */}
      <main className="main-content">
        {/* Header */}
        <header className="header">
          <button className="toggle-btn" onClick={toggleSidebar}>
            ☰
          </button>
          <h1 style={{ fontSize: 24 }}>Junk Pi Wallet</h1>
        </header>

        {/* File Upload Section */}
        <div style={{ display: "flex", flexDirection: "row", padding: 16, columnGap: 8 }}>
          <Input
            placeholder="Search junk wallet address"
            size="small"
            value={searchTerm}
            onChange={handleInputChange}
          />
          <Button onClick={handleSearch} style={{ padding: "5px" }}>
            Search
          </Button>
          <section className="chart" style={{}}>
            <Button
              icon={<UploadOutlined />}
              onClick={() => fileInputRef.current?.click()}
            >
              Click to Upload
            </Button>
            <input
              ref={fileInputRef}
              hidden
              type="file"
              id="images"
              accept="txt/*"
              required
              onChange={handleDebouncedFileUpload}
            />
          </section>
        </div>

        <Table
          style={{ padding: "0px 16px" }}
          dataSource={files}
          columns={columns}
          pagination={{ pageSize: 10 }}
          scroll={{
            x: "max-content", // Enables horizontal scroll if content overflows
            y: 500, // Sets vertical scroll height
          }}
          className="custom-table"
        />
      </main>
    </div>
  );
};

export default Dashboard;
