import ReactPixel from "react-facebook-pixel";

export const initMetaPixel = (pixelId) => {
  ReactPixel.init(pixelId); // Initialize Meta Pixel
};

export const trackPageView = () => {
  ReactPixel.pageView(); // Track page views
};

export const trackEvent = (eventName, eventParams) => {
  ReactPixel.track(eventName, eventParams); // Track custom events
};
