import React, { useEffect, useState } from "react";
import MyModalEn from "./login-modal-global"
import { PRODUCTS } from "./products"
import CartIcon from "./cart"

export default function Component() {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {

    const listener = (e) => {
      e.preventDefault();
      e.stopPropagation()
      setIsOpen(true);
      document.body.style.overflow = "hidden"
    };

    const nodes = document.getElementsByClassName(
      "image-background p-absolute"
    );

    const nodes3 = document.getElementsByClassName(
      "anc9"
    );

    const nodes2 = document.getElementsByClassName(
      "text-block-css full-width"
    );

    nodes2 && nodes &&
      [...Array.from(nodes), ...Array.from(nodes2), ...Array.from(nodes3)].map((element) => {
        element.addEventListener("click", listener);
      });


    return () => {
      nodes &&
        Array.from(nodes).map((element) => {
          element.removeEventListener("click", listener);
        });
    }
  }, []);

  return (
    <>
      <MyModalEn isOpen={isOpen} onClose={() => {
        setIsOpen(false);
        document.body.style.overflow = "auto"
      }} />
      <div
        className="pageview"
        style={{ fontFamily: '"Encode Sans Semi Expanded", san-serif' }}
      >
        <div
          id="webcake-alert-msg"
          className="w-alert-message-wrapper"
          style={{ display: "none", zIndex: 9999999 }}
        />
        <div id="w-dzaxfisc">
          <div className="section-wrapper full-width full-height p-relative">
            <div className="section-background p-absolute full-width full-height" />
            <div className="section-container full-height p-relative">
              <div id="w-ccey1qss" className="p-absolute">
                <div className="rectangle-css full-mask-size mask-position full-height full-width" />
              </div>
              <div id="w-14ggq8ke" className="p-absolute">
                <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                  <div className="image-background p-absolute" />
                </div>
              </div>
              <a
                href="/global/thanhtoanπ"
                id="w-mvnvcv2k"
                className="p-absolute group-auto-scroll sroll-size"
              >
                <div className="group-container">
                  <div id="w-w4hksnca" className="p-absolute">
                    <div className="rectangle-css full-mask-size mask-position full-height full-width" />
                  </div>
                  <div id="w-ruu6nolc" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h5 className="text-block-css full-width">LOGIN</h5>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div id="w-b0yilwn0">
          <div className="section-wrapper full-width full-height p-relative">
            <div className="section-background p-absolute full-width full-height" />
            <div className="section-container full-height p-relative">
              <div id="w-cvtmdjt2" className="p-absolute">
                <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                  <div className="image-background p-absolute" />
                </div>
              </div>
              <div id="w-s96qishq" className="p-absolute">
                <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                  <div className="image-background p-absolute" />
                </div>
              </div>
              <div id="w-otbffm7b" className="p-absolute">
                <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                  <div className="image-background p-absolute" />
                </div>
              </div>
              <div
                id="w-dqm39j4b"
                className="p-absolute group-auto-scroll sroll-size"
              >
                <div className="group-container">
                  <div id="w-26j0cui2" className="com-text-block p-absolute">
                    <div className="text-block">
                      {/* <h4 className="text-block-css full-width">
                        Mobile
                        <br />
                        Accessories
                        <br />
                      </h4> */}
                    </div>
                  </div>
                  {/* <div id="w-olmpvdip" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h5 className="text-block-css full-width">Just 0.01π</h5>
                    </div>
                  </div> */}
                </div>
              </div>



            </div>
          </div>
        </div>
        <h4 style={{
          margin: "24px 0px",
          "borderColor": "#e5e7eb", "borderStyle": "solid", "fontSize": "20px", "fontWeight": "700", "textAlign": "center"
        }}>
          Featured products
        </h4>
        <div style={{ width: "100%", display: "flex", flexDirection: "column", rowGap: 50 }}>
          {
            PRODUCTS.map(item => {

              return (
                <div className="anc9" style={{ width: "100%", overflowX: "auto", display: "flex", flexDirection: "row", columnGap: 12 }}>
                  {
                    item.items.map(childItem => {
                      return <div style={{ display: "flex", flexDirection: "column", width: "max-content", alignItems: "center", justifyContent: "space-between" }}>
                        <>
                          <div style={{ width: 195, height: 195, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img id="123" src={childItem.image} style={{ width: 195, height: 195, ...childItem?.customSize }} />

                          </div>
                          <span style={{
                            fontSize: 16,
                            fontWeight: 700,
                            textAlign: "center",
                            width: 195,
                            height: 48
                          }}>{childItem.label}</span>
                          <div
                            style={{
                              textAlign: "center",
                              color: "#ffb93e",
                              fontSize: 16,
                              margin: "10px 0px"
                            }}
                          >{childItem.price}π</div>
                        </>
                        <span style={{
                          borderColor: "#e5e7eb",
                          borderRadius: "50px",
                          borderStyle: "solid",
                          background: "#007bf7",
                          width: 165,
                          height: 30,
                          fontSize: 14,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                          cursor: "pointer",
                          flexDirection: "row"
                        }}><CartIcon /> BUY NOW</span>
                      </div>
                    })
                  }
                </div>
              )
            })
          }
        </div>

        {/* <div id="w-jvh9yr9k" style={{ marginTop: 48 }}>
          <div className="section-wrapper full-width full-height p-relative">
            <div className="section-background p-absolute full-width full-height" />
            <div className="section-container full-height p-relative">
              <div id="w-gnz28n1e" className="com-text-block p-absolute">
                <div className="text-block">
                  <h4 className="text-block-css full-width">
                    New products
                  </h4>
                </div>
              </div>
              <div
                id="w-ve566prh"
                className="p-absolute group-auto-scroll sroll-size"
              >
                <div className="group-container">
                  <div
                    id="w-x6prk4jw"
                    className="p-absolute group-auto-scroll sroll-size"
                  >
                    <div className="group-container">
                      <div id="w-wtos91h6" className="p-absolute">
                        <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                          <div className="image-background p-absolute" ></div>
                        </div>
                      </div>
                      <div
                        id="w-29c2g79p"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            Xe đạp MTB GIANT
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-hmkmg4mm"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">55π</h5>
                        </div>
                      </div>
                      <div
                        id="w-cxh73teo"
                        className="p-absolute group-auto-scroll sroll-size"
                      >
                        <div className="group-container">
                          <div id="w-lxp5pr3r" className="p-absolute">
                            <div className="rectangle-css full-mask-size mask-position full-height full-width" />
                          </div>
                          <div
                            id="w-8c1r2l8i"
                            className="com-text-block p-absolute"
                          >
                            <div className="text-block">
                              <h5 className="text-block-css full-width">
                                BUY NOW
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-hpwoygav"
                    className="p-absolute group-auto-scroll sroll-size"
                  >
                    <div className="group-container">
                      <div id="w-txx836rq" className="p-absolute">
                        <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                          <div className="image-background p-absolute" />
                        </div>
                      </div>
                      <div
                        id="w-jsj8uxw5"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            Xe đạp MTB GIANT
                            <br />
                            <br />
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-0iruzmd9"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            65π
                            <br />
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-3hj2zm30"
                        className="p-absolute group-auto-scroll sroll-size"
                      >
                        <div className="group-container">
                          <div id="w-q10wzonr" className="p-absolute">
                            <div className="rectangle-css full-mask-size mask-position full-height full-width" />
                          </div>
                          <div
                            id="w-tl1lvr3t"
                            className="com-text-block p-absolute"
                          >
                            <div className="text-block">
                              <h5 className="text-block-css full-width">
                                BUY NOW
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="w-w5vey4vu"
                className="p-absolute group-auto-scroll sroll-size"
              >
                <div className="group-container">
                  <div
                    id="w-syuptp7h"
                    className="p-absolute group-auto-scroll sroll-size"
                  >
                    <div className="group-container">
                      <div id="w-3d2b9rp10" className="p-absolute">
                        <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                          <div className="image-background p-absolute" />
                        </div>
                      </div>
                      <div
                        id="w-vivl9jut"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            Combo NY
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-q8egoq8j"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">2π</h5>
                        </div>
                      </div>
                      <div
                        id="w-spqhvnwi"
                        className="p-absolute group-auto-scroll sroll-size"
                      >
                        <div className="group-container">
                          <div id="w-nc1lkk85" className="p-absolute">
                            <div className="rectangle-css full-mask-size mask-position full-height full-width" />
                          </div>
                          <div
                            id="w-ibxx0i3h"
                            className="com-text-block p-absolute"
                          >
                            <div className="text-block">
                              <h5 className="text-block-css full-width">
                                BUY NOW
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-f4qgunwt"
                    className="p-absolute group-auto-scroll sroll-size"
                  >
                    <div className="group-container">
                      <div id="w-38mosx52" className="p-absolute">
                        <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                          <div className="image-background p-absolute" />
                        </div>
                      </div>
                      <div
                        id="w-hrr3jg1e"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            Áo polo
                            <br />
                            <br />
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-vuhjzkfh"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            1π
                            <br />
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-cxlrn2zj"
                        className="p-absolute group-auto-scroll sroll-size"
                      >
                        <div className="group-container">
                          <div id="w-q0kp3pfs" className="p-absolute">
                            <div className="rectangle-css full-mask-size mask-position full-height full-width" />
                          </div>
                          <div
                            id="w-n717s9jf"
                            className="com-text-block p-absolute"
                          >
                            <div className="text-block">
                              <h5 className="text-block-css full-width">
                                BUY NOW
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div> */}


        <div id="w-eiatjh0w" style={{ marginTop: 48 }}>
          <div className="section-wrapper full-width full-height p-relative">
            <div className="section-background p-absolute full-width full-height" />
            <div className="section-container full-height p-relative">
              <div
                id="w-aqutq2cc"
                className="p-absolute group-auto-scroll sroll-size"
              >
                <div className="group-container">
                  <div id="w-pe5bxegp" className="p-absolute">
                    <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                      <div className="image-background p-absolute" />
                    </div>
                  </div>
                  <div id="w-kmfboaxt" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h4 className="text-block-css full-width">
                        Iphone Case
                        <br />
                      </h4>
                    </div>
                  </div>
                  <div id="w-6n791tog" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h5 className="text-block-css full-width">
                        Discover Now
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="w-ajfovjld"
                className="p-absolute group-auto-scroll sroll-size"
              >
                <div className="group-container">
                  <div id="w-t887wzqa" className="p-absolute">
                    <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                      <div className="image-background p-absolute" />
                    </div>
                  </div>
                  <div id="w-odwsbw0b" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h4 className="text-block-css full-width">
                        SUPERIOR
                        <br />
                        SOUND
                        <br />
                      </h4>
                    </div>
                  </div>
                  <div id="w-ty4pw0wa" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h5 className="text-block-css full-width">25% Off</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="w-qh919ugd">
          <div className="section-wrapper full-width full-height p-relative">
            <div className="section-background p-absolute full-width full-height" />
            <div className="section-container full-height p-relative">
              <div id="w-ol10qmhp" className="com-text-block p-absolute">
                <div className="text-block">
                  <h4 className="text-block-css full-width">
                    Today’s recommended product
                    <br />
                  </h4>
                </div>
              </div>
              <div
                id="w-fmsvwn2c"
                className="p-absolute group-auto-scroll sroll-size"
              >
                <div className="group-container">
                  <div
                    id="w-1a8q2w5w"
                    className="p-absolute group-auto-scroll sroll-size"
                  >
                    <div className="group-container">
                      <div id="w-vnem6jlo" className="p-absolute">
                        <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                          <div className="image-background p-absolute" />
                        </div>
                      </div>
                      <div
                        id="w-3v4oh86j"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            iMac 2020 MXWV2 27 inch 5K Core i7-3.8GHz
                            <br />
                            <br />
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-dd5b6f5k"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">57π</h5>
                        </div>
                      </div>
                      <div
                        id="w-00i8cup9"
                        className="p-absolute group-auto-scroll sroll-size"
                      >
                        <div className="group-container">
                          <div id="w-t91k6ery" className="p-absolute">
                            <div className="rectangle-css full-mask-size mask-position full-height full-width" />
                          </div>
                          <div
                            id="w-a397g5eb"
                            className="com-text-block p-absolute"
                          >
                            <div className="text-block">
                              <h5 className="text-block-css full-width">
                                <CartIcon />BUY NOW
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-do80xy0f"
                    className="p-absolute group-auto-scroll sroll-size"
                  >
                    <div className="group-container">
                      <div id="w-7mok52rk" className="p-absolute">
                        <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                          <div className="image-background p-absolute" />
                        </div>
                      </div>
                      <div
                        id="w-1sogza0p"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            Máy Chơi Game PS Portal
                            <br />
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-80djelbw"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">37π</h5>
                        </div>
                      </div>
                      <div
                        id="w-j389anc9"
                        className="p-absolute group-auto-scroll sroll-size"
                      >
                        <div className="group-container">
                          <div id="w-fl33m32b" className="p-absolute">
                            <div className="rectangle-css full-mask-size mask-position full-height full-width" />
                          </div>
                          <div
                            id="w-kmt3cyw1"
                            className="com-text-block p-absolute"
                          >
                            <div className="text-block">
                              <h5 className="text-block-css full-width">
                                <CartIcon />BUY NOW
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-sx292bbd"
                    className="p-absolute group-auto-scroll sroll-size"
                  >
                    <div className="group-container">
                      <div id="w-jk05m6g8" className="p-absolute">
                        <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                          <div className="image-background p-absolute" />
                        </div>
                      </div>
                      <div
                        id="w-unod3lfc"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            Laptop Asus Gaming ROG Strix SCAR
                            <br />
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-8rvdbjv1"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">33π</h5>
                        </div>
                      </div>
                      <div
                        id="w-6u10rn8r"
                        className="p-absolute group-auto-scroll sroll-size"
                      >
                        <div className="group-container">
                          <div id="w-ubbyxqg9" className="p-absolute">
                            <div className="rectangle-css full-mask-size mask-position full-height full-width" />
                          </div>
                          <div
                            id="w-hg7rfz8o"
                            className="com-text-block p-absolute"
                          >
                            <div className="text-block">
                              <h5 className="text-block-css full-width">
                                <CartIcon /> BUY NOW
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="w-6cqx5d3r"
                className="p-absolute group-auto-scroll sroll-size"
              >
                <div className="group-container">
                  <div
                    id="w-oupmaqck"
                    className="p-absolute group-auto-scroll sroll-size"
                  >
                    <div className="group-container">
                      <div id="w-np5b8p8s" className="p-absolute">
                        <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                          <div className="image-background p-absolute" />
                        </div>
                      </div>
                      <div
                        id="w-sugxzggv"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            OPPO Reno8 Z 5G 8GB – 256GB
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-4ltegp6k"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">19π</h5>
                        </div>
                      </div>
                      <div id="w-aye83ovb" className="p-absolute">
                        <div className="rectangle-css full-mask-size mask-position full-height full-width" />
                      </div>
                      <div
                        id="w-czm38yqc"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            <CartIcon />BUY NOW
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-corwtzci"
                    className="p-absolute group-auto-scroll sroll-size"
                  >
                    <div className="group-container">
                      <div id="w-omnjd5on" className="p-absolute">
                        <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                          <div className="image-background p-absolute" />
                        </div>
                      </div>
                      <div
                        id="w-cl0qa140"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            Tai nghe Logitech G733 LIGHTSPEED&nbsp;
                            <br />
                            <br />
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-w9hg11r6"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            6- 8π
                            <br />
                          </h5>
                        </div>
                      </div>
                      <div id="w-t8sp0ejz" className="p-absolute">
                        <div className="rectangle-css full-mask-size mask-position full-height full-width" />
                      </div>
                      <div
                        id="w-z889lnh9"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            <CartIcon /> BUY NOW
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-jwxna9o4"
                    className="p-absolute group-auto-scroll sroll-size"
                  >
                    <div className="group-container">
                      <div id="w-6w3gbr3o" className="p-absolute">
                        <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                          <div className="image-background p-absolute" />
                        </div>
                      </div>
                      <div
                        id="w-4mzjsjm3"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            Smart Tivi Samsung 4K 75 inch
                            <br />
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-5iquxwq0"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            47π
                            <br />
                          </h5>
                        </div>
                      </div>
                      <div id="w-sfdsdw4r" className="p-absolute">
                        <div className="rectangle-css full-mask-size mask-position full-height full-width" />
                      </div>
                      <div
                        id="w-d5bsgg8p"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            <CartIcon />  BUY NOW
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-dfyg9fdr"
                    className="p-absolute group-auto-scroll sroll-size"
                  >
                    <div className="group-container">
                      <div id="w-2d9iloc0" className="p-absolute">
                        <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                          <div className="image-background p-absolute" />
                        </div>
                      </div>
                      <div
                        id="w-ferpdwsh"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            Điện thoại iPhone 14 Pro Max 512GB Chính hãng
                            <br />
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-za47gnkq"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            28π
                            <br />
                          </h5>
                        </div>
                      </div>
                      <div id="w-ayjk4x4o" className="p-absolute">
                        <div className="rectangle-css full-mask-size mask-position full-height full-width" />
                      </div>
                      <div
                        id="w-psaigv6d"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                              <CartIcon /> BUY NOW

                            </div>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="w-fqzggpnj" style={{ marginTop: 50 }}>
          <div className="section-wrapper full-width full-height p-relative">
            <div className="section-background p-absolute full-width full-height" />
            <div className="section-container full-height p-relative">
              <div id="w-be54568h" className="p-absolute">
                <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                  <div className="image-background p-absolute" />
                </div>
              </div>
              <div
                id="w-ppqz13od"
                className="p-absolute group-auto-scroll sroll-size"
              >
                <div className="group-container">
                  <div id="w-4uhgah2a" className="p-absolute">
                    <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                      <div className="image-background p-absolute" />
                    </div>
                  </div>
                  <div id="w-djhhhr68" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h5 className="text-block-css full-width">
                        APPLE
                        <br />
                        COLLETION
                        <br />
                      </h5>
                    </div>
                  </div>
                  <div id="w-5ovh55jj" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h5 className="text-block-css full-width">
                        SALE OFF 25% NOW
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="w-lwvl98y5"
                className="p-absolute group-auto-scroll sroll-size"
              >
                <div className="group-container">
                  <div id="w-w0z0rt4l" className="p-absolute">
                    <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                      <div className="image-background p-absolute" />
                    </div>
                  </div>
                  <div id="w-7xfdb7yl" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h5 className="text-block-css full-width">
                        NEW APPLE
                        <br />
                      </h5>
                    </div>
                  </div>
                  <div id="w-noymtitq" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h5 className="text-block-css full-width">
                        RETINA NOW IN COLOSSAL AND GINORMOUS.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div id="w-5ciwc3g5" className="p-absolute">
                <div className="rectangle-css full-mask-size mask-position full-height full-width" />
              </div>
              <div id="w-neer77hv" className="com-text-block p-absolute">
                <div className="text-block">
                  <h4 className="text-block-css full-width">COMPUTERS</h4>
                </div>
              </div>
              <div id="w-haxgmgdv" className="com-text-block p-absolute">
                <div className="text-block">
                  <h4 className="text-block-css full-width">SMARTPHONES</h4>
                </div>
              </div>
              <div id="w-5wa1vt20" className="com-text-block p-absolute">
                <div className="text-block">
                  <h4 className="text-block-css full-width">TABLETS</h4>
                </div>
              </div>
              <div
                id="w-1x0lu5h9"
                className="p-absolute group-auto-scroll sroll-size"
              >
                <div className="group-container">
                  <div
                    id="w-n2lggc6v"
                    className="p-absolute group-auto-scroll sroll-size"
                  >
                    <div className="group-container">
                      <div id="w-18n7qwbr" className="p-absolute">
                        <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                          <div className="image-background p-absolute" />
                        </div>
                      </div>
                      <div
                        id="w-w6c062kp"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            Laptop Asus Gaming ROG Strix SCAR
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-rk820f7a"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">33π</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-iyvf6ax0"
                    className="p-absolute group-auto-scroll sroll-size"
                  >
                    <div className="group-container">
                      <div id="w-8ltfj8i4" className="p-absolute">
                        <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                          <div className="image-background p-absolute" />
                        </div>
                      </div>
                      <div
                        id="w-992mc4oj"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            iMac 2020 MXWV2 27 inch 5K Core i7-3.8GHz
                            <br />
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-zujym5ti"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">57π</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-dcdcl911"
                    className="p-absolute group-auto-scroll sroll-size"
                  >
                    <div className="group-container">
                      <div id="w-8ace4dcn" className="p-absolute">
                        <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                          <div className="image-background p-absolute" />
                        </div>
                      </div>
                      <div
                        id="w-yjjvfmp2"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            Smart Tivi QNED LG 4K 65 inch
                            <br />
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-opj75y0s"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">31π</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-e5cp1j1t"
                    className="p-absolute group-auto-scroll sroll-size"
                  >
                    <div className="group-container">
                      <div id="w-81n84c8c" className="p-absolute">
                        <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                          <div className="image-background p-absolute" />
                        </div>
                      </div>
                      <div
                        id="w-onpbdg98"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            Laptop Apple MacBook Air 13 inch
                            <br />
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-6evf3b77"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">50π</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="w-rn9n5oqq"
                    className="p-absolute group-auto-scroll sroll-size"
                  >
                    <div className="group-container">
                      <div id="w-i6w3bvhn" className="p-absolute">
                        <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                          <div className="image-background p-absolute" />
                        </div>
                      </div>
                      <div
                        id="w-6h34uc8c"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">
                            Smart Tivi Samsung 4K 75 inch
                            <br />
                          </h5>
                        </div>
                      </div>
                      <div
                        id="w-tk1euzil"
                        className="com-text-block p-absolute"
                      >
                        <div className="text-block">
                          <h5 className="text-block-css full-width">47π</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="w-gxkcp0z6">
          <div className="section-wrapper full-width full-height p-relative">
            <div className="section-background p-absolute full-width full-height" />
            <div className="section-container full-height p-relative">
              <div
                id="w-8ugd87m5"
                className="p-absolute group-auto-scroll sroll-size"
              >
                <div className="group-container">
                  <div id="w-4hfig4jy" className="p-absolute">
                    <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                      <div className="image-background p-absolute" />
                    </div>
                  </div>
                  <div id="w-pba3vu6y" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h4 className="text-block-css full-width">
                        FREE SHIPPING
                        <br />
                      </h4>
                    </div>
                  </div>
                  <div id="w-m5jn7zrn" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h5 className="text-block-css full-width">
                        For all Order Over $200
                        <br />
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="w-vxe3pgss"
                className="p-absolute group-auto-scroll sroll-size"
              >
                <div className="group-container">
                  <div id="w-4gml89a0" className="p-absolute">
                    <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                      <div className="image-background p-absolute" />
                    </div>
                  </div>
                  <div id="w-0ke1ry76" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h4 className="text-block-css full-width">
                        SECURE PAYMENT
                        <br />
                        <br />
                      </h4>
                    </div>
                  </div>
                  <div id="w-opllpa5x" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h5 className="text-block-css full-width">
                        All cards accepted
                        <br />
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="w-2eoq7lkj"
                className="p-absolute group-auto-scroll sroll-size"
              >
                <div className="group-container">
                  <div id="w-hli22g5j" className="p-absolute">
                    <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                      <div className="image-background p-absolute" />
                    </div>
                  </div>
                  <div id="w-ntc8jjqj" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h4 className="text-block-css full-width">
                        HELP CENTER
                        <br />
                        <br />
                      </h4>
                    </div>
                  </div>
                  <div id="w-g632ckg4" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h5 className="text-block-css full-width">
                        24/7 Support System
                        <br />
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="w-qftyh6ko"
                className="p-absolute group-auto-scroll sroll-size"
              >
                <div className="group-container">
                  <div id="w-tm383ert" className="p-absolute">
                    <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                      <div className="image-background p-absolute" />
                    </div>
                  </div>
                  <div id="w-fxtbyie6" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h4 className="text-block-css full-width">
                        DISCOUNT
                        <br />
                        <br />
                      </h4>
                    </div>
                  </div>
                  <div id="w-5o31r2c6" className="com-text-block p-absolute">
                    <div className="text-block">
                      <h5 className="text-block-css full-width">
                        40% Discount for Member
                        <br />
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="w-ri5ksffmen">
          <div className="section-wrapper full-width full-height p-relative">
            <div className="section-background p-absolute full-width full-height" />
            <div className="section-container full-height p-relative">
              <div id="w-hes3y3k4" className="p-absolute">
                <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
                  <div className="image-background p-absolute" />
                </div>
              </div>
              <div id="w-huzrotfj" className="com-text-block p-absolute">
                <div className="text-block">
                  <h6 className="text-block-css full-width">
                    Address 1: Yeonsu-gu, Incheon, Korean
                    <br />
                    Surabaya St Menteng Central Jakarta City, Indonesia
                    <br />
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="popup-backdrop"
        className="popup-backdrop full-width full-height d-none"
      />
      <div
        id="lightbox"
        className="lightbox-backdrop full-width full-height d-none"
      />
      <div id="login-popup" className="login-popup-wrapper">
        <div className="backdrop-login" />
        <div className="popup-default-login">
          <div className="popup-login-title">Alert</div>
          <div className="popup-login-content">Expired or Invalid Code!</div>
          <div className="popup-login-btn">OK</div>
        </div>
      </div>
      <link
        rel="preload"
        href="css?family=Roboto:100,300,400,700,900|Encode%20Sans%20Semi%20Expanded:100,300,400,700,900|Encode%20Sans%20Expanded:100,300,400,700,900&display=swap"
        as="style"
        onload="this.onload=null;this.rel='stylesheet';"
      />
      <link rel="stylesheet" href="animate/animatev4.css?v=1" type="text/css" />
      <link
        rel="preload"
        href="render_iconfont/iconfont.css?v=1"
        as="style"
        onload="this.onload=null;this.rel='stylesheet';"
      />
      <img
        height={1}
        width={1}
        alt=""
        style={{ display: "none" }}
        src="page_view.gif?pid=79916553-fcb0-46b2-8b58-567d7fce0ee4"
      />

    </>
  );
}
