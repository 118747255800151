export const PRODUCTS = [
  {
    label: "iphone",
    items: [
      {
        label: "iPhone 16 Pro Max 256GB",
        image: require("./assets/1/iphone-16-pro-max_256.webp"),
        price: 75
      },
      {
        label: "iPhone 16 Pro Max 1TB",
        image: require("./assets/1/iphone-16-pro-max_1024.webp"),
        price: 85
      },
      {
        label: "iPhone 16 Pro 256GB Titan White",
        image: require("./assets/1/iphone-16-pro-titan-white-256.webp"),
        price: 71
      },
    ],
  },
  {
    label: "iphone",
    items: [
      {
        label: "iPhone 15 Pro Max 256GB",
        image: require("./assets/1/s500x500/fwebp/7d/37/3c/e9/7664933b6c83f6e655ac3d6caf28368f03f1fd104cc5f60867748844.jpg"),
        price: 65
      },
      {
        label: "iPhone 14 Pro Max 512GB",
        image: require("./assets/1/iphone-14-pro_max.webp"),
        price: 28
      },
      {
        label: "iPhone 13 Pro Max 256GB",
        image: require("./assets/1/iphone-13-pro-max.jpeg"),
        price: 12
      },
    ],
  },
  {
    label: "Oppo",
    items: [
      {
        label: "OPPO Reno11 F 5G Tím",
        image: require("./assets/1/oppo-reno11-f.jpg"),
        price: 34
      },
      {
        label: "OPPO Find N3 16GB 512GB",
        image: require("./assets/1/OPPO-Find-N3-16GB-512GB.webp"),
        price: 90
      },
    ],
  },
  {
    label: "Samsung",
    items: [
      {

        label: "Samsung Galaxy S24 Ultra 512GB",
        image: require("./assets/1/s550x550/fwebp/20/b7/ff/be/e996c700232fd8ff318c33f6921b012e102bb4e830d9da1bc5e7f895.jpg"),
        price: 57
      },
      {
        label: "Samsung Galaxy Z Fold5",
        image: require("./assets/1/samsung-galaxy-z-fold5-5g.jpeg"),
        price: 60
      },
    ],
  },
  {
    label: "apple watch",
    items: [
      {
        label: "Apple Watch Series 9 GPS + Cellular 45mm",
        image: require("./assets/1/apple_watch_sr9.webp"),
        price: 45
      },
      {
        label: "Apple Watch Series 8 45mm GPS",
        image: require("./assets/1/Apple-Watch-Series-8-45mm.webp"),
        price: 32
      },
    ],
  },
  {
    label: "laptop macBook",
    items: [
      {
        customSize: { width: 195, height: "auto" },
        label: "MacBook Pro 14 inch M3 Pro ",
        image: require("./assets/1/macbook-pro-14-m3.webp"),
        price: 50
      },
      {
        customSize: { width: 195, height: "auto" },
        label: "MacBook Air 13 inch M1 2020",
        image: require("./assets/1/s650x550/fwebp/3b/04/0d/f7/252755cd0adadf69acad84b73441c86ad2d4de20a576b14eda147755.jpg"),
        price: 35
      },
    ],
  },
  {
    label: "tai nghe",
    items: [
      {
        label: "AirPods Pro",
        image: require("./assets/1/s500x500/fwebp/2b/54/70/73/c39a112e98c42f54631b47c2608f3b0779855c194e4a7bd6c809de27.jpg"),
        price: 18
      },
      {
        label: "Logitech G733 LIGHTSPEED",
        image: require("./assets/1/s500x500/fwebp/5b/20/b4/07/5ab701b6dc5498ff611d848d5bbed6348296acde56958c7abfaebd7e.webp"),
        price: "5~6"
      },
    ],
  },
  {
    label: "tivi",
    items: [
      {
        customSize: { width: 195, height: "auto" },
        label: "Smart Tivi Samsung 4K 75 inch",
        image: require("./assets/1/s650x550/fwebp/b7/bf/24/e0/0724a521f058c86481d6850d45edd162c52fc71f099194dbe12ac722.jpg"),
        price: 47
      },
      {
        customSize: { width: 175, height: "auto", transform: "translateY(13px)" },
        label: "Smart Tivi QNED LG 4K 65 inch",
        image: require("./assets/1/s600x550/fwebp/3d/f0/8c/17/fc8bae884309466cdff1ebe8d4e81b0f0ec99f1138bf3ad7e8b263f2.png"),
        price: 45
      },
    ],
  },
  {
    label: "tu lanh",
    items: [
      {
        label: "Fefrigerator Smart Inverter™",
        image: require("./assets/1/tulanh1.png"),
        price: 55
      },
      {
        label: "Fefrigerator LG Inverter 519L",
        image: require("./assets/1/tulanh2.webp"),
        price: 70
      },
    ],
  },
  {
    label: "may giat",
    items: [
      {
        customSize: { width: 275, height: "auto" },
        label: "Electrolux UltimateCare 300 Inverter 10 kg",
        image: require("./assets/1/may-giat-electrolux-inverter-10-kg-ewf1024m3sb-1-1.jpg"),
        price: 32
      },
      {
        customSize: { width: 163, height: "auto" },
        label: "Panasonic Inverter 10kg",
        image: require("./assets/1/maygiat2.png"),
        price: 38
      },
    ],
  },

  {
    label: "dieu hoa",
    items: [
      {
        customSize: { width: "100%", height: "auto" },
        label: "Panasonic Inverter 2.5 HP",
        image: require("./assets/1/may-lanh-panaso_main_751_1020.webp"),
        price: 160
      },
      {
        customSize: { width: "100%", height: "auto" },
        label: "LG DUALCOOL™ Inverter 1.5 HP",
        image: require("./assets/1/D-1.avif"),
        price: 100
      },

    ],
  },
  {
    label: "xe dap",
    items: [
      {
        label: "GIANT MTB bike",
        image: require("./assets/1/xe-dap-giant-1.jpeg"),
        price: 55
      },
      {
        label: "GIANT MTB bike",
        image: require("./assets/1/xe-dap-giant-1.jpeg"),
        price: 65
      },
    ],
  },
  {
    label: "xe dap dien",
    items: [
      {
        label: "Electric Bicycle",
        image: require("./assets/1/electric-bicycle-1.jpg"),
        price: 24
      },
      {
        label: "Electric Bicycle for 3 peoples",
        image: require("./assets/1/Hbaff1b6d1bb24fb3a65c8e3f3eea992cK.png"),
        price: 59
      },

    ],
  },
];
