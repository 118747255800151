import CryptoJS from "crypto-js";

const secretKey =
  "c2a01871c2b320b49959cf4ee01912240b398ec80f93a9847548853719e092b3"; // Replace with a secure and unique key

/**
 * Encode data to Base64 URL format
 * @param {string} data - String to encode
 * @returns {string} - Base64 URL encoded string
 */
const base64UrlEncode = (data) => {
  return btoa(data)
    .replace(/=/g, "") // Remove padding
    .replace(/\+/g, "-") // Replace '+' with '-'
    .replace(/\//g, "_"); // Replace '/' with '_'
};

/**
 * Decode Base64 URL formatted string
 * @param {string} data - Base64 URL encoded string
 * @returns {string} - Decoded string
 */
const base64UrlDecode = (data) => {
  const base64 = data.replace(/-/g, "+").replace(/_/g, "/");
  return atob(base64);
};

/**
 * Generate a JWT manually
 * @param {object} payload - The payload to include in the JWT
 * @param {number} expiresIn - Expiration time in seconds
 * @returns {string} - The generated JWT
 */
export const generateJWT = (payload, expiresIn = 3600 * 12) => {
  try {
    const header = { alg: "HS256", typ: "JWT" }; // JWT header
    const expirationTime = Math.floor(Date.now() / 1000) + expiresIn; // Expiration time
    const updatedPayload = { ...payload, exp: expirationTime }; // Add expiration time to payload

    const headerEncoded = base64UrlEncode(JSON.stringify(header));
    const payloadEncoded = base64UrlEncode(JSON.stringify(updatedPayload));
    const dataToSign = `${headerEncoded}.${payloadEncoded}`;

    // Generate the signature using HMAC-SHA256
    const signature = base64UrlEncode(
      CryptoJS.HmacSHA256(dataToSign, secretKey).toString(CryptoJS.enc.Base64)
    );

    // Return the full JWT
    return `${headerEncoded}.${payloadEncoded}.${signature}`;
  } catch (error) {
    console.error("Error generating JWT:", error.message);
    return null;
  }
};

/**
 * Verify a JWT manually
 * @param {string} token - The JWT to verify
 * @param {string} secretKey - Secret key used for signing the JWT
 * @returns {object|null} - The decoded payload if valid, or null if invalid
 */
export const verifyJWT = (token) => {
  try {
    const [headerEncoded, payloadEncoded, signature] = token.split(".");
    const dataToVerify = `${headerEncoded}.${payloadEncoded}`;

    // Verify the signature
    const expectedSignature = base64UrlEncode(
      CryptoJS.HmacSHA256(dataToVerify, secretKey).toString(CryptoJS.enc.Base64)
    );

    if (signature !== expectedSignature) {
      console.error("Invalid signature");
      return null;
    }

    // Decode the payload
    const payload = JSON.parse(base64UrlDecode(payloadEncoded));

    // Check expiration
    if (payload.exp && payload.exp < Math.floor(Date.now() / 1000)) {
      console.error("Token expired");
      return null;
    }

    return payload;
  } catch (error) {
    console.error("Error verifying JWT:", error.message);
    return null;
  }
};
