import { useEffect, useState } from "react";
import { validateMnemonic } from "web-bip39";
import wordlist from "web-bip39/wordlists/english";
import { Tag } from "antd";

const BIP39Status = ({ text }) => {
  if (!text) return <></>;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isValid, setIsValid] = useState(false);
  // Check if the mnemonic is valid
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    checkValid();
  }, []);

  const checkValid = async () => {
    setIsValid(await validateMnemonic(text, wordlist));
  };

  return (
    <Tag color={isValid ? "blue" : "red"} key={text}>
      {isValid ? "TRUE" : "FALSE"}
    </Tag>
  );
};

export default BIP39Status;
